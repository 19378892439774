<template>
  <div>
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Course title
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item :to="{ name: 'dashboard-mechtech' }">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :active="true"
                >
                  Course title
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Home" active>
        <b-row>
          <b-col>
            <b-card class="shadow-sm">
              <b-card-body class="mt-4">
                Course Title
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-card class="shadow-sm">
              <b-card-body>
                <h6>Announcements</h6>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card class="shadow-sm">
              <b-card-body>
                Stream Content
              </b-card-body>
            </b-card>
            <b-card class="shadow-sm">
              <b-card-body>
                Stream Content
              </b-card-body>
            </b-card>
            <b-card class="shadow-sm">
              <b-card-body>
                Stream Content
              </b-card-body>
            </b-card>
            <b-card class="shadow-sm">
              <b-card-body>
                Stream Content
              </b-card-body>
            </b-card>
            <b-card class="shadow-sm">
              <b-card-body>
                Stream Content
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Learnings">
        <b-row>
          <b-col>
            <b-card class="shadow-sm">
              <b-card-body class="m-0 p-0">
                <b-row>
                  <b-col>
                    <h5>Course Modules</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="accordion" role="tablist">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <a v-b-toggle.accordion-1 class="w-100">
                            <b-row>
                              <b-col md="10">
                                <h2>Module 1</h2>
                              </b-col>
                              <b-col md="2" class="d-flex justify-content-end">
                                <feather-icon
                                  size="32"
                                  icon="ChevronDownIcon"
                                  class="mr-50"
                                />
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <hr class="m-0 mb-1">
                              </b-col>
                            </b-row>
                          </a>
                        </b-card-header>
                        <b-collapse
                          id="accordion-1"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body class="pt-0">
                            <b-row class="mb-2">
                              <b-col md="10" class="d-flex flex-row">
                                <b-link :to="{ name: 'course-learning', params: {slug: 'course-1', subject: 'subject-1'} }" class="w-100">
                                  <h5>1. Subject 1</h5>
                                </b-link>
                              </b-col>
                              <b-col md="2" />
                            </b-row>
                            <b-row class="mb-2">
                              <b-col md="10" class="d-flex flex-row">
                                <h5>2. Subject 2</h5>
                              </b-col>
                              <b-col md="2" />
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Assignments">
        <p>I'm a disabled tab!</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

export default {
  name: "Detail",
  components: {
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>

</style>
